import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import BackButton from '../components/ui/BackButton';
import Button from '../components/ui/Button';
import InputDate from '../components/ui/InputDate';
import Input from '../components/ui/Input';
import InputGroup from '../components/ui/InputGroup';
import Label from '../components/ui/Label';
import StepperLayout from '../components/layouts/StepperLayout';
import {
  getEndDate,
  getSingleRateUsage,
  getStartDate,
  setBlockRatesUsage,
  setEndDate,
  setPeakOffPeakUsage,
  setSingleRateUsage,
  setStartDate,
} from '../utils/localStorage/localStorageFunctions';
import { navigateAndSetActiveStep } from '../utils/utils';
import { StepUrl } from '../utils/enums';
import usePreloadPageData from '../hooks/usePreloadPageData';
import SEOLayout from '../components/layouts/SEOLayout';

const SingleRateTariffSchema = Yup.object().shape(
  {
    totalUsage: Yup.number()
      .typeError('Usage must be a number')
      .min(0.01, 'Usage must be more than 0')
      .max(10_000, 'Please check the usage amount')
      .required('Please, specify the usage amount'),
    startDate: Yup.date()
      .typeError('Please, specify the start date')
      .when('endDate', (endDate: any, schema: any) => {
        return (
          endDate && schema.max(endDate, 'Start date needs to before end date')
        );
      })
      .required('Please, specify the start date'),
    endDate: Yup.date()
      .typeError('Please, specify the end date')
      .when(
        'startDate',
        (startDate: any, schema: any) =>
          startDate &&
          schema.min(startDate, 'End date needs to be after start date')
      )
      .required('Please, specify the end date'),
  },
  [['startDate', 'endDate']]
);

const SingleRateTariff = () => {
  const [initialFormValues] = useState(() => {
    const singleRateUsageLS = getSingleRateUsage();
    const startDateLS = getStartDate();
    const endDateLS = getEndDate();

    return {
      totalUsage: singleRateUsageLS?.toString() || '',
      startDate: startDateLS || '',
      endDate: endDateLS || '',
    };
  });

  const handleNext = (values: typeof initialFormValues) => {
    setSingleRateUsage(parseInt(values.totalUsage, 10));
    setPeakOffPeakUsage(null);
    setBlockRatesUsage(null);
    setStartDate(values.startDate);
    setEndDate(values.endDate);

    navigateAndSetActiveStep(StepUrl.URL_CONTROLLED_LOAD);
  };

  const handleBack = () => {
    navigateAndSetActiveStep(StepUrl.URL_ABOUT_YOUR_BILL);
  };

  usePreloadPageData(StepUrl.URL_CONTROLLED_LOAD, StepUrl.URL_ABOUT_YOUR_BILL);

  return (
    <SEOLayout>
      <StepperLayout
        currentStage={2}
        currentStep={6}
        pageUrl={StepUrl.URL_SINGLE_RATE_TARIFF}
      >
        <div className="group my-3">
          <Formik
            initialValues={initialFormValues}
            onSubmit={(values) => {
              handleNext(values);
            }}
            validationSchema={SingleRateTariffSchema}
            validateOnChange
          >
            {(props) => (
              <Form>
                <h2 className="my-2">Single rate tariff</h2>
                <p className="sub-heading">
                  This information can be found on your bill.
                </p>
                <InputGroup className="my-2">
                  <Label htmlFor="input-start-date">Start date</Label>
                  <InputDate id="input-start-date" name="startDate" />
                </InputGroup>
                <InputGroup className="my-2">
                  <Label htmlFor="input-end-date">End date</Label>
                  <InputDate id="input-end-date" name="endDate" />
                </InputGroup>
                <InputGroup className="my-2">
                  <Label htmlFor="input-total-usage">Total usage</Label>
                  <Input
                    id="input-total-usage"
                    name="totalUsage"
                    type="number"
                    unit="kWh"
                    controls
                    min={0.01}
                    max={10_000}
                    step={0.01}
                  />
                </InputGroup>
                <Button
                  className="mt-5"
                  type="submit"
                  disabled={
                    props.values.totalUsage === '' ||
                    !props.values.startDate ||
                    !props.values.endDate
                  }
                >
                  Continue
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <BackButton text="Back" onClick={handleBack} />
      </StepperLayout>
    </SEOLayout>
  );
};

export default SingleRateTariff;
